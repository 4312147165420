:root {
    --baseline: 8px;

    --color-copy: #3d3270;
    --color-error: #d8000c;

    --border-color: #e9e9e9;
    --border: 1px solid var(--border-color);

    --body-font: 'Ubuntu', sans-serif;
    --title-font: 'Ubuntu', sans-serif;
}

html,
body,
#root {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#root {
    display: flex;
    flex-direction: column;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin-top: 0;
}

body {
    margin: 0;
    margin-top: 30px;
    font-family: var(--body-font);
    color: var(--gray20);
    line-height: 1.5;
    font-weight: 300;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
    font-family: var(--body-font);
    font-size: 1rem;
}

svg {
    fill: var(--color-copy);
}

p {
    margin-bottom: 1.25em;
    font-weight: 400;
}

a {
    color: var(--denim-blue);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 2.75rem 0 1rem;
    margin-top: 0;
    font-family: var(--title-font), sans-serif;
    font-weight: 500;
    line-height: 1.16;
}

h1 {
    margin-top: 0;
    font-size: 1.802rem;
}

h2 {
    font-size: 2.25rem;
}

h3 {
    font-size: 1.424rem;
}

h4 {
    font-size: 1.1875rem;
}

h5 {
    font-size: 1.125rem;
}

small,
.text_small {
    font-size: 0.889rem;
}
